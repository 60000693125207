const theme = {
  font: {
    primary: `'Founders Grotesk', serif`,
    secondary: `'Founders Grotesk', serif`,
  },
  font_size: {
    small: 'font-size: 20px; line-height: 30px',
    regular: 'font-size: 24px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px; ',
    larger: 'font-size: 46px; line-height: 1.25; font-weight: 500;',
    xlarge: 'font-size: 70px; line-height: 1.05; font-weight: 600;',
  },
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#ABA8AF',
      light: '#564F62',
      regular: '#211E26',
    },
    red: {
      regular: '#07a0c3'
    },
    style1: { 
      blue: "#001f34",
      blue2: "#185a9d",
      indigo: "#1f4260",
      charcoal: "#315065",
      green: "#43cea2",
      lava: "#4d4739"
    },
    primary: '#ebebeb',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;



// https://coolors.co/001f34-193753-1f4260-315065-95de37-4d4739
// {"Prussian Blue":"001f34","Prussian Blue 2":"193753","Indigo Dye":"1f4260","Charcoal":"315065","Sheen Green":"95de37","Dark Lava":"4d4739"}

// https://coolors.co/ba2d0b-d5f2e3-73ba9b-003e1f-01110a
// {"International Orange Engineering":"ba2d0b","Honeydew":"d5f2e3","Ocean Green":"73ba9b","British Racing Green":"003e1f","Dark Jungle Green":"01110a"}

// https://coolors.co/54478c-2c699a-048ba8-0db39e-16db93-83e377-b9e769-efea5a-f1c453-f29e4c
// {"Dark Slate Blue":"54478c","Sapphire Blue":"2c699a","Blue Munsell":"048ba8","Keppel":"0db39e","Medium Aquamarine":"16db93","Light Green":"83e377","Inchworm":"b9e769","Corn":"efea5a","Maize Crayola":"f1c453","Sandy Brown":"f29e4c"}